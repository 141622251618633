<style lang="scss" scoped>
.page-serials {
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
}
</style>

<template>
    <div class="page-serials">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fr">
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部类型" size="medium" filterable clearable
                        v-model="listQuery.ownerType" @change="changeOwner">
                        <el-option v-for="opt in arrAssetOwnerType" :key="opt.value" :label="opt.desc" :value="opt.value"></el-option>
                    </el-select>
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部仓库" size="medium" filterable clearable
                        v-model="listQuery.ownerId" @change="_search" v-if="listQuery.ownerType === valOwnerWarehouse()">
                        <el-option v-for="ws in allWarehouse" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="全部使用者" size="medium" filterable clearable
                        v-model="listQuery.ownerId" @change="_search" v-if="listQuery.ownerType === valOwnerAssetUser()">
                        <el-option v-for="ws in allAssetUser" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="请输入关键字查询" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="medium">
                <el-table-column label="序列号" prop="id"></el-table-column>
                <el-table-column label="图片【分类-条形码】资产名称" min-width="280">
                    <template slot-scope="scope" v-if="scope.row.asset">
                        <el-image class="asset-image" v-if="scope.row.asset.imageUrl"
                            :src="uploadBaseUrl + scope.row.asset.imageUrl" 
                            :preview-src-list="[uploadBaseUrl + scope.row.asset.imageUrl]">
                            <div slot="error">
                                <i class="el-icon-picture-outline error-image"></i>
                            </div>
                        </el-image>
                        <span>{{ mxTranceAssetData(scope.row.asset) }}</span>
                        <span :class="mxTranceAssetData(scope.row.asset) ? '' : 'mrgl10'">{{ scope.row.asset.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="所属事业部" prop="company.name" min-width="95"></el-table-column>
                <el-table-column label="拥有者类型【名称】" min-width="150px">
                    <template slot-scope="scope">
                        {{ tranceOwnerType(scope.row) }}<span class="col_primary" v-if="tranceOwnerName(scope.row)">【{{ tranceOwnerName(scope.row) }}】</span>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
export default {
    name: "pageSerials",
    mixins: [enumConfigs],
    data() {
        return {
            tableData: [],
            total: null,
            listQuery: {
                companyId: "",
                assetId: "",
                assetName: "",
                ownerType: null,
                ownerId: null,
                keywords: "",
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            allWarehouse: [],
            allAssetUser: []
        }
    },
    async mounted() {
        await this.initData()
    },
    methods: {
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        async initData() {
            await this.getAllWarehouse()
            await this.getAllAssetUser()
            this.getDataList()
        },
        getDataList() {
            window.$common.fullLoading()
            funStock.getSerialNumberDatas(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async getAllWarehouse() {
            await funInfomation.searchWarehouse({ companyId: this.listQuery.companyId }).then(res => {
                this.allWarehouse = res.items
            })
        },
        async getAllAssetUser() {
            await funInfomation.searchAssetUser({ companyId: this.listQuery.companyId }).then(res => {
                this.allAssetUser = res.items
            })
        },
        // 转换类型
        tranceOwnerType(row) {
            let temp = this.arrAssetOwnerType.find(x => x.value === row.ownerType)
            return temp && temp.desc || ""
        },
        // 所属仓库
        tranceOwnerName(row) {
            let temp = {}
            if (row.ownerType === this.valOwnerWarehouse()) {
                temp = this.allWarehouse.find(x => {
                    return row.ownerId === x.id
                })
            } else if (row.ownerType === this.valOwnerAssetUser()) {
                temp = this.allAssetUser.find(x => {
                    return row.ownerId === x.id
                })
            }
            return temp && temp.name || ""
        },
        // 类型改变
        changeOwner() {
            this.listQuery.companyId = ""
            this.listQuery.ownerId = ""
            this._search()
        },
        _search() {
            this.listQuery.page = 1
            this.getDataList()
        }
    }
}
</script>